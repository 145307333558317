import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

import axiosInstance from '../../http/axiosConfig';

import Slider from '../../commons/Slider';
import LoadingCarousel from './LoadingDresserCarousel';
import UserCard from '../../commons/UserCard';

const useSeeMoreStyles = makeStyles(theme => ({
  seeMore: {
    minWidth: '270px',
    height: '261px',
    marginTop: theme.spacing(1),
    backgroundColor: 'rgb(252, 247, 242)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    boxShadow: '0 3px 15px 0 rgba(0, 0, 0, 0.1)',
    '& > *': {
      color: '#414042',
      fontWeight: 600,
      fontSize: '1.3rem',
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '180px',
      maxWidth: '180px',
      height: '372px'
    }
  }
}));

const SeeMore = props => {
  const classes = useSeeMoreStyles(props);
  const { carouselLink } = props;
  return (
    <NavLink to={carouselLink} className={classes.seeMore}>
      <Typography>
        Ver más
        <ChevronRight color="primary" />
      </Typography>
    </NavLink>
  );
};

const useCarouselStyles = makeStyles(theme => ({
  rowHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2, 2, 0.5, 2),
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  centeredFlex: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerSubtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
    textDecoration: 'none'
  }
}));

const DresserCarousel = ({
  title,
  source,
  target,
  onCarouselFails,
  isScrolling,
  ...props
}) => {
  const [loadingCarousel, setLoadingCarousel] = useState(true);
  const [users, setUsers] = useState([]);

  const classes = useCarouselStyles();

  useEffect(() => {
    const axiosSource = axios.CancelToken.source();
    axiosInstance
      .get(source, { cancelToken: axiosSource.token })
      .then(response => {
        const users = response.data.items || response.data;
        if (users.length === 0) {
          onCarouselFails(title);
          return;
        }

        setUsers(users);
        setLoadingCarousel(false);
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          onCarouselFails(title);
        }
      });
    return () => {
      axiosSource.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loadingCarousel && <LoadingCarousel noAnimation={isScrolling} />}
      {!loadingCarousel && (
        <div className={classes.homeRow} {...props}>
          <div className={classes.rowHeader}>
            <Typography
              variant="h6"
              component="h2"
              color="secondary"
              style={{ fontWeight: 500 }}
            >
              {title}
            </Typography>
            <Typography
              component={NavLink}
              to={target}
              className={classes.headerSubtitle}
            >
              · ver todo
              <ChevronRight color="primary" />
            </Typography>
          </div>
          <Slider scrollAmount={400}>
            {users.map(user => (
              <UserCard key={user.id} user={user} noProductsFetch />
            ))}
            <SeeMore carouselLink={target} />
          </Slider>
        </div>
      )}
    </>
  );
};

export default DresserCarousel;
