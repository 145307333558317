import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Popper, Fade, Box, ClickAwayListener } from '@material-ui/core';

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: theme.shape.borderRadius
  },
  popper: ({ navbarMenu }) => ({
    zIndex: 9999,
    marginTop: navbarMenu ? '33px' : '9px'
  }),
  box: {
    borderRadius: theme.shape.borderRadius
  }
});

const Menu = ({
  anchorEl,
  open,
  navbarMenu,
  onClose,
  classes,
  children,
  noClickAway,
  ...props
}) => {
  const [mouseAppeared, setMouseAppeared] = useState(false);

  useEffect(() => {
    // If after 2 seconds the menu wasnt visited
    // by the mouse, close the menu.
    const timeout = setTimeout(() => {
      if (!mouseAppeared) {
        onClose();
      }
    }, 2000);
    return () => {
      // Ummonting.
      // If the menu was closed before the timeout
      // was executed, clear it out.
      clearTimeout(timeout);
    };
  }, [mouseAppeared, onClose]);

  return (
    <>
      <Popper
        className={classes.popper}
        placement="bottom"
        anchorEl={anchorEl}
        style={{ pointerEvents: open ? 'all' : 'none' }}
        open={open}
        {...props}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={noClickAway ? () => true : onClose}>
            <Fade {...TransitionProps}>
              <Box
                onMouseEnter={() => setMouseAppeared(true)}
                onMouseLeave={onClose}
                className={classes.box}
                boxShadow={3}
              >
                <div className={classes.root}>
                  {React.cloneElement(children, { onClose })}
                </div>
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default withStyles(styles)(Menu);
