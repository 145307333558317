import React from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { Typography } from '@material-ui/core';

import { TEXT_BANNERS_RETRIEVED } from '../redux/actions/types';
import { getTextBanners } from '../redux/actions/publicity';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(2, 3, 2, 3),
    backgroundColor: fade('#efeb6a', 0.5),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    '& > *': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 500
    }
  },
  liveBanner: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#BF0404',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      fontWeight: 500,
      fontSize: '18px',
      stroke: '#000',
      color: '#000'
    }
  },
  flashWithUrl: {
    cursor: 'pointer'
  },
  liveText: {
    color: '#fffefe',
    fontSize: '1rem',
    marginLeft: theme.spacing(1)
  }
}));

const RTVMarketingFlash = ({
  location,
  containerProps,
  liveEnabled = false
}) => {
  const [banner, setBanner] = React.useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const banners = useSelector(
    state => state.publicity.textBanners,
    shallowEqual
  );
  const loggedInUser = useSelector(state => state.auth.user);
  const bannersStatus = useSelector(state => state.publicity.status);

  /*
   * Will always receive at least one banner element in array
   * Logic to select banner prioritizing amongst those that were segmented,
   * that may be targeted at this particular kind of user.
   * If several of segmented banners apply, the one with the highest priority is selected.
   * If none of the segmented applies, selects from amongst remaining banners according to priority */
  const selectTopPriorityBannerFromApplicableByLocation = locationApplicableBanners => {
    const segmentedBanners = locationApplicableBanners.filter(
      banner =>
        (banner.sellers && banner.sellers.length !== 0) ||
        (banner.user_types && banner.user_types.length !== 0)
    );
    if (segmentedBanners.length > 0) {
      const applicableSegmentedBanners = segmentedBanners
        .filter(banner => {
          let sellerCheckNeeded = banner.sellers && banner.sellers.length !== 0;
          let roleCheckNeeded =
            banner.user_types && banner.user_types.length !== 0;
          let sellerCheckPassed = false;
          let roleCheckPassed = false;

          if (loggedInUser) {
            if (sellerCheckNeeded) {
              sellerCheckPassed = banner.sellers.includes(loggedInUser.id);
            }

            if (roleCheckNeeded) {
              roleCheckPassed = loggedInUser.roles.some(role =>
                banner.user_types.includes(role)
              );
            }
          }

          /* If seller is present, and type too, seller is taken into account as applicable
           * regardless of type, as an exception */
          return roleCheckPassed || sellerCheckPassed;
          // If several apply, sorts by priority
        })
        .sort((a, b) => a.priority - b.priority);

      if (applicableSegmentedBanners.length > 0) {
        return applicableSegmentedBanners[0];
      }
    }

    // If no particular banner applies, selects from amongst banners without segmentation according to priority
    return (
      locationApplicableBanners
        .filter(element => {
          return !segmentedBanners.includes(element);
        })
        .sort((a, b) => b.priority - a.priority)[0] || null
    );
  };

  // Mounting logic.
  React.useEffect(() => {
    // DISCLAIMER: React guarantees that dispatch function identity
    // is stable and won’t change on re-renders. so we don't have
    // to put it on the dependencies array.
    dispatch(getTextBanners());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let selectedBanner = null;
    let bannersMatched = [];
    if (
      bannersStatus === TEXT_BANNERS_RETRIEVED &&
      banners.length > 0 &&
      !banner
    ) {
      // Will always select a live banner as active over a non-live related banner
      if (
        liveEnabled &&
        banners.some(banner => banner.location === 'LIVE_GENERAL_LOCATIONS')
      ) {
        bannersMatched = banners.filter(
          banner => banner.location === 'LIVE_GENERAL_LOCATIONS'
        );
        selectedBanner = selectTopPriorityBannerFromApplicableByLocation(
          bannersMatched
        );
      }

      // If banner wasn't selected from live ones, banner will be taken from location-specific
      if (!selectedBanner) {
        bannersMatched = banners.filter(banner => banner.location === location);
        if (bannersMatched.length > 0) {
          selectedBanner = selectTopPriorityBannerFromApplicableByLocation(
            bannersMatched
          );
        }
      }

      setBanner(selectedBanner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannersStatus]);

  if (!banner) {
    return <></>;
  }

  return (
    <div
      className={clsx({
        [classes.root]: banner.location !== 'LIVE_GENERAL_LOCATIONS',
        [classes.liveBanner]: banner.location === 'LIVE_GENERAL_LOCATIONS',
        [classes.flashWithUrl]: banner.url !== null
      })}
      {...containerProps}
      onClick={() => {
        if (banner.url) {
          window.open(banner.url, '_blank');
        }
      }}
      role="presentation"
    >
      {banner.location === 'LIVE_GENERAL_LOCATIONS' && (
        <span
          role="img"
          aria-label="blinking-spot"
          // Class included in animation.scss file
          className="blink"
          style={{ marginRight: '4px' }}
        >
          ⚪
        </span>
      )}
      <Typography
        className={clsx({
          [classes.liveText]: banner.location === 'LIVE_GENERAL_LOCATIONS'
        })}
      >
        {banner.message}
      </Typography>
    </div>
  );
};

export default RTVMarketingFlash;
