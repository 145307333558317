import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { SvgIcon, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import RTVButton from '../RTVButton';
import { ReactComponent as PremiumIcon } from '../../images/Icons/Premium.svg';
import { ReactComponent as ShowroomIcon } from '../../images/Icons/Showroom.svg';
import { ReactComponent as CelebritiesIcon } from '../../images/Icons/Celebrities.svg';
import { ReactComponent as ZapIcon } from '../../images/Icons/zap.svg';
import { ReactComponent as SolidaryIcon } from '../../images/Icons/Solidary.svg';

const styles = theme => ({
  dresserIcon: {
    fontSize: '4.8rem',
    margin: theme.spacing(1)
  },
  dresserMenu: {
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr'
  },
  dresserButton: {},
  dresserButtonContent: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    textDecoration: 'none',
    color: '#000'
  },
  showMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.8rem',
    textDecoration: 'none',
    color: '#000'
  },
  buttonTitle: {
    fontSize: '1rem',
    fontWeight: 800
  }
});

const gradients = {
  premium: ['#C372F0', '#F4ABA8'],
  showroom: ['#AFE2BC', '#B0CDF2'],
  celebrities: ['#EE6184', '#FAD2A5'],
  vip: ['#B2C8FD', '#F3BDBD'],
  solidary: ['#79D9A3', '#99C8D1'],
  today: ['#C190FA', '#F6AE52']
};

const getGradient = type =>
  `linear-gradient(90deg, ${gradients[type][0]} 0%, ${gradients[type][1]} 100%)`;

const DresserButton = withStyles(styles)(
  ({ icon: Icon, title, subtitle, classes, viewBox, ...otherProps }) => (
    <Link className={classes.dresserButtonContent} {...otherProps}>
      <SvgIcon viewBox={viewBox} className={classes.dresserIcon}>
        <Icon />
      </SvgIcon>
      <Typography className={classes.buttonTitle}>{title}</Typography>
      <Typography>{subtitle}</Typography>
    </Link>
  )
);

const DresserMenu = ({ classes }) => (
  <div className={classes.dresserMenu}>
    <RTVButton
      variant="custom"
      style={{
        background: getGradient('premium')
      }}
      custom={
        <DresserButton
          icon={PremiumIcon}
          title="PREMIUM"
          subtitle="Las mejores vendedoras!"
          viewBox="0 0 72 37"
          to="/vestidores/premium"
        />
      }
    />
    <RTVButton
      variant="custom"
      style={{
        background: getGradient('celebrities')
      }}
      custom={
        <DresserButton
          icon={CelebritiesIcon}
          title="CELEBRITIES"
          subtitle="Famosas e influencers!"
          viewBox="0 0 50 43"
          to="/vestidores/celebrities"
        />
      }
    />
    <RTVButton
      variant="custom"
      style={{
        background: getGradient('solidary')
      }}
      custom={
        <DresserButton
          icon={SolidaryIcon}
          title="SOLIDARIOS"
          subtitle="ONG's y vendedores solidarios"
          viewBox="0 0 50 43"
          to="/vestidores/solidary"
        />
      }
    />
    <RTVButton
      variant="custom"
      style={{
        background: getGradient('today')
      }}
      custom={
        <DresserButton
          icon={ZapIcon}
          title="EN PROMO HOY"
          viewBox="0 0 44 59"
          to="/vestidores/promos"
        />
      }
    />
    <RTVButton
      variant="custom"
      style={{
        background: getGradient('showroom')
      }}
      custom={
        <DresserButton
          icon={ShowroomIcon}
          title="SHOWROOMS"
          subtitle="Diseñadoras y emprendedoras"
          viewBox="0 0 48 31"
          to="/vestidores/showrooms"
        />
      }
    />
    <RTVButton
      variant="custom"
      custom={
        <Link className={classes.showMoreButton} to="/vestidores">
          <Typography className={classes.buttonTitle}>VER TODO</Typography>

          <ChevronRight color="primary" />
        </Link>
      }
    />
  </div>
);

export default withStyles(styles)(DresserMenu);
