import React, { Component, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Link,
  SvgIcon,
  Badge,
  withWidth,
  Hidden,
  Button
} from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons';

import RTVRating from '../RTVRating';
import RTVAvatar from '../RTVAvatar';
import RoleIcon from '../RoleIcon';
import RTVDialog from '../dialogs/RTVDialog';
import { logout } from '../../redux/actions/auth';

import { ReactComponent as BillIcon } from '../../images/Icons/invoice.svg';
import { ReactComponent as DollarIcon } from '../../images/Icons/dollar-sign.svg';
import { ReactComponent as TagIcon } from '../../images/Icons/tag.svg';
import { ReactComponent as ShoppingCartIcon } from '../../images/Icons/shopping-cart.svg';
import { ReactComponent as HeartIcon } from '../../images/Icons/heart.svg';
import { ReactComponent as ThumbsUpIcon } from '../../images/Icons/thumbs-up.svg';
import { ReactComponent as SettingsIcon } from '../../images/Icons/settings.svg';
import { ReactComponent as LogoutIcon } from '../../images/Icons/log-out.svg';
import { ReactComponent as PackageIcon } from '../../images/Icons/package.svg';
import { ReactComponent as MessageIcon } from '../../images/Icons/message-circle.svg';
import { ReactComponent as TruckIcon } from '../../images/Icons/truck.svg';
import { ReactComponent as FileTextIcon } from '../../images/Icons/file-text.svg';
import { ReactComponent as BellIcon } from '../../images/Icons/bell.svg';
import { ReactComponent as UserIcon } from '../../images/Icons/user.svg';
import { ReactComponent as ZapIcon } from '../../images/Icons/zap.svg';
import { ReactComponent as HammerIcon } from '../../images/Icons/martillo-02.svg';
import { USER_PRIVATE_STATS_RETRIEVED } from '../../redux/actions/types';
import numberWithCommas from '../../utils/numberWithCommas';

const triggerResize = () => {
  window.dispatchEvent(new Event('resize'));
};

const styles = theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  rating: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5)
    }
  },
  profileSection: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column'
    },
    '& > *': {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2)
    }
  },
  profileHeader: props => {
    const gradients = {
      ROLE_PREMIUM: theme.palette.gradients.premium,
      ROLE_SHOWROOM: theme.palette.gradients.showroom,
      ROLE_SHOWROOM_PENDING: theme.palette.gradients.showroom,
      ROLE_VIP: theme.palette.gradients.vip,
      ROLE_CELEBRITY: theme.palette.gradients.celebrity,
      DEAFULT: theme.palette.gradients.regular
    };
    const role = props.user
      ? props.user.type || props.user.roles.find(r => gradients[r])
      : null;
    const gradient = gradients[role] || gradients.DEAFULT;

    return {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      [theme.breakpoints.down('sm')]: {
        borderBottomLeftRadius: 0,
        borderTopRightRadius: theme.shape.borderRadius
      },
      background: `linear-gradient(90deg, ${gradient[0]} ${
        gradients[role] ? '0%' : '55%'
      }, ${gradient[1]} 100%)`
    };
  },
  profileMenu: {
    position: 'relative'
  },
  avatar: {
    width: '4em',
    height: '4em',
    marginBottom: theme.spacing(2)
  },
  treeView: {
    marginRight: theme.spacing(8),
    minWidth: '230px',
    maxWidth: '230px',
    '& > *': {
      width: '90%',
      margin: theme.spacing(1)
    }
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    color: theme.palette.secondary.main
  },
  labelIcon: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(2)
  },
  labelIconBadge: {
    fontSize: '0.8rem'
  },
  badge: {
    marginRight: theme.spacing(2)
  },
  miniBadge: {
    minWidth: 'unset',
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: '0.6em'
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    fontSize: '0.85rem'
  },
  balance: {
    margin: '13px',
    fontWeight: 500,
    position: 'absolute',
    right: 0,
    zIndex: 3,
    fontSize: '0.9rem'
  },
  labelBackground: {
    '&:hover, &:focus': {
      backgroundColor: '#FFF'
    }
  },
  chevronRight: {
    color: '#f15f67'
  },
  zapIcon: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(2),
    '& > svg': {
      '& > path': {
        stroke: '#403f41',
        fill: 'none',
        strokeWidth: '3px'
      }
    }
  },
  dollarIcon: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(2),
    '& > svg': {
      '& > g': {
        '& > path': {
          stroke: '#403f41',
          strokeWidth: '7%'
        }
      }
    }
  },
  hammerIcon: {
    fontSize: '0.9rem',
    marginRight: theme.spacing(2),
    '& > svg': {
      '& > g': {
        '& > path': {
          stroke: '#403f41',
          strokeWidth: '6%'
        }
      }
    }
  }
});

const RTVTreeItem = withStyles(styles)(
  ({
    classes,
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    badgeProps,
    isChild,
    ...props
  }) => {
    const [mouseOver, setMouseOver] = useState(false);

    function handleMouseEnter() {
      setMouseOver(true);
    }

    function handleMouseLeave() {
      setMouseOver(false);
    }

    return (
      <TreeItem
        classes={{ label: classes.labelBackground }}
        label={
          <div
            className={classes.labelRoot}
            onMouseOver={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onFocus={() => undefined}
          >
            {isChild && mouseOver && (
              <ChevronRightIcon
                color="inherit"
                className={classes.chevronRight}
              />
            )}
            <LabelIcon color="inherit" />
            {badgeProps ? (
              <Badge showZero={false} {...badgeProps}>
                <Typography variant="body2" className={classes.labelText}>
                  {labelText}
                </Typography>
              </Badge>
            ) : (
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
            )}
            {labelInfo && (
              <Typography variant="caption" color="inherit">
                {labelInfo}
              </Typography>
            )}
          </div>
        }
        {...props}
      />
    );
  }
);

class ProfileMenu extends Component {
  constructor(props) {
    super(props);

    const salesGroup = props.privateStats.COUNTERS_GROUP_SELLING
      ? props.privateStats.COUNTERS_GROUP_SELLING.find(
          g => g.status === 'STATUS_ACCREDITED' && Number(g.total) > 0
        )
      : undefined;
    const productsGroup = props.privateStats.COUNTERS_GROUP_PRODUCTS
      ? props.privateStats.COUNTERS_GROUP_PRODUCTS.find(
          g => g.status === 'STATUS_OFFER' && Number(g.total) > 0
        )
      : undefined;
    const sellerComments = props.privateStats.COUNTERS_GROUP_COMMENTS
      ? Number(props.privateStats.COUNTERS_GROUP_COMMENTS.seller.unseen)
      : 0;

    const sellerCounters = {
      sales: salesGroup ? Number(salesGroup.total) : 0,
      products: productsGroup ? Number(productsGroup.total) : 0,
      comments: sellerComments
    };

    const buyingGroup = props.privateStats.COUNTERS_GROUP_BUYING
      ? props.privateStats.COUNTERS_GROUP_BUYING.find(
          g =>
            g.status === 'STATUS_WAITING_ON_FINANCIAL_RECORD_IN' &&
            Number(g.total) > 0
        )
      : undefined;

    const buyerComments = props.privateStats.COUNTERS_GROUP_COMMENTS
      ? Number(props.privateStats.COUNTERS_GROUP_COMMENTS.buyer.unseen)
      : 0;

    const buyerCounters = {
      purchases: buyingGroup ? Number(buyingGroup.total) : 0,
      comments: buyerComments
    };

    this.state = {
      privateStats: props.privateStats,
      sellerCount: Object.keys(sellerCounters).reduce(
        (c, g) => c + sellerCounters[g],
        0
      ),
      buyerCount: Object.keys(buyerCounters).reduce(
        (c, g) => c + buyerCounters[g],
        0
      ),
      loggingOut: false,
      sellerCounters,
      buyerCounters
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userStatus !== this.props.userStatus) {
      if (this.props.userStatus === USER_PRIVATE_STATS_RETRIEVED) {
        const salesGroup = this.props.privateStats.COUNTERS_GROUP_SELLING.find(
          g => g.status === 'STATUS_ACCREDITED' && Number(g.total) > 0
        );
        const productsGroup = this.props.privateStats.COUNTERS_GROUP_PRODUCTS.find(
          g => g.status === 'STATUS_OFFER' && Number(g.total) > 0
        );
        const sellerComments = Number(
          this.props.privateStats.COUNTERS_GROUP_COMMENTS.seller.unseen
        );

        const sellerCounters = {
          sales: salesGroup ? Number(salesGroup.total) : 0,
          products: productsGroup ? Number(productsGroup.total) : 0,
          comments: sellerComments
        };

        const buyingGroup = this.props.privateStats.COUNTERS_GROUP_BUYING.find(
          g =>
            g.status === 'STATUS_WAITING_ON_FINANCIAL_RECORD_IN' &&
            Number(g.total) > 0
        );

        const buyerComments = Number(
          this.props.privateStats.COUNTERS_GROUP_COMMENTS.buyer.unseen
        );

        const buyerCounters = {
          purchases: buyingGroup ? Number(buyingGroup.total) : 0,
          comments: buyerComments
        };

        this.setState({
          privateStats: this.props.privateStats,
          sellerCount: Object.keys(sellerCounters).reduce(
            (c, g) => c + sellerCounters[g],
            0
          ),
          buyerCount: Object.keys(buyerCounters).reduce(
            (c, g) => c + buyerCounters[g],
            0
          ),
          sellerCounters,
          buyerCounters
        });
      }
    }
  }

  render() {
    const { user, classes, logout: logoutAction } = this.props;

    let avatarSrc = null;
    if (this.props.privateStats && this.props.privateStats.media) {
      avatarSrc =
        this.props.privateStats.media.default_big ||
        this.props.privateStats.media.user_big;
    }

    if (user === null) {
      return '';
    }

    return (
      <div className={classes.profileSection}>
        <RTVDialog
          open={this.state.loggingOut}
          onClose={() => this.setState({ loggingOut: false })}
          title="Estas por cerrar sesion!"
          message="Estas segura?"
          dialogProps={{
            style: { zIndex: 10000 }
          }}
          actionSection={
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.setState({ loggingOut: false })}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={logoutAction}
              >
                SI, SALIR
              </Button>
            </>
          }
        />
        <div className={classes.profileHeader}>
          <Hidden mdUp>
            {this.props.user !== null && <RoleIcon user={this.props.user} />}
          </Hidden>
          <Hidden smDown>
            <RTVAvatar className={classes.avatar} src={avatarSrc} user={user} />
          </Hidden>
          <Typography>{user.username.toUpperCase()}</Typography>
          <RTVRating
            className={classes.rating}
            name="rating"
            value={
              this.props.privateStats ? this.props.privateStats.stars || 0 : 0
            }
            readOnly
          />
          <Link
            style={{ cursor: 'pointer' }}
            onClick={() =>
              this.props.push({
                pathname: `/vestidor/${user.username}`,
                state: { ...user }
              })
            }
            color="secondary"
          >
            Ver mi vestidor
          </Link>
        </div>
        <div className={classes.profileMenu}>
          {this.state.privateStats.account_total_balance !== undefined && (
            <div className={classes.balance}>
              {`$${numberWithCommas(
                this.state.privateStats.account_total_balance
              )}`}
            </div>
          )}
          <TreeView className={classes.treeView} onNodeToggle={triggerResize}>
            <RTVTreeItem
              nodeId="1"
              labelIcon={() => (
                <SvgIcon viewBox="0 0 18 30" className={classes.dollarIcon}>
                  <DollarIcon />
                </SvgIcon>
              )}
              onClick={() => this.props.push('/mi-cuenta')}
              labelText="Mi cuenta"
            />
            <RTVTreeItem
              nodeId="2"
              labelIcon={() => (
                <Badge
                  className={classes.badge}
                  color="primary"
                  variant="dot"
                  invisible={this.state.sellerCount === 0}
                >
                  <SvgIcon className={classes.labelIconBadge}>
                    <TagIcon />
                  </SvgIcon>
                </Badge>
              )}
              labelText="Vendiendo"
            >
              <RTVTreeItem
                isChild
                nodeId="9"
                badgeProps={{
                  classes: {
                    badge: classes.miniBadge
                  },
                  color: 'primary',
                  badgeContent: this.state.sellerCounters.sales
                }}
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <PackageIcon />
                  </SvgIcon>
                )}
                onClick={() => this.props.push('/mis-ventas')}
                labelText="Mis ventas"
              />
              <RTVTreeItem
                isChild
                nodeId="10"
                badgeProps={{
                  classes: {
                    badge: classes.miniBadge
                  },
                  color: 'primary',
                  badgeContent: this.state.sellerCounters.products
                }}
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <TagIcon />
                  </SvgIcon>
                )}
                labelText="Mis productos"
                onClick={() => this.props.push('/mis-ventas/mis-productos')}
              />
              <RTVTreeItem
                isChild
                nodeId="11"
                badgeProps={{
                  classes: {
                    badge: classes.miniBadge
                  },
                  color: 'primary',
                  badgeContent: this.state.sellerCounters.comments
                }}
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <MessageIcon />
                  </SvgIcon>
                )}
                onClick={() => this.props.push('/mis-ventas/mensajes')}
                labelText="Mensajes"
              />
              <RTVTreeItem
                isChild
                nodeId="12"
                labelIcon={() => (
                  <SvgIcon viewBox="0 0 44 59" className={classes.zapIcon}>
                    <ZapIcon className={classes.zapIcon} />
                  </SvgIcon>
                )}
                onClick={() => this.props.push('/mis-ventas/promociones')}
                labelText="Promociones"
              />
              <RTVTreeItem
                isChild
                nodeId="13"
                onClick={() =>
                  this.props.push('/configuracion/medios-de-envio')
                }
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <TruckIcon />
                  </SvgIcon>
                )}
                labelText="Medios de envío"
              />
              <RTVTreeItem
                isChild
                nodeId="14"
                onClick={() =>
                  this.props.push('/configuracion/medios-de-cobro')
                }
                labelIcon={() => (
                  <SvgIcon viewBox="0 0 18 30" className={classes.dollarIcon}>
                    <DollarIcon />
                  </SvgIcon>
                )}
                labelText="Medios de cobro"
              />
            </RTVTreeItem>
            <RTVTreeItem
              nodeId="3"
              labelIcon={() => (
                <Badge
                  className={classes.badge}
                  color="primary"
                  variant="dot"
                  invisible={this.state.buyerCount === 0}
                >
                  <SvgIcon className={classes.labelIconBadge}>
                    <ShoppingCartIcon />
                  </SvgIcon>
                </Badge>
              )}
              labelText="Comprando"
            >
              <RTVTreeItem
                isChild
                nodeId="15"
                badgeProps={{
                  classes: {
                    badge: classes.miniBadge
                  },
                  color: 'primary',
                  badgeContent: this.state.buyerCounters.purchases
                }}
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <ShoppingCartIcon />
                  </SvgIcon>
                )}
                onClick={() => this.props.push('/mis-compras')}
                labelText="Mis compras"
              />
              <RTVTreeItem
                isChild
                nodeId="16"
                badgeProps={{
                  classes: {
                    badge: classes.miniBadge
                  },
                  color: 'primary',
                  badgeContent: this.state.buyerCounters.comments
                }}
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <MessageIcon />
                  </SvgIcon>
                )}
                onClick={() => this.props.push('/mis-compras/mensajes')}
                labelText="Mensajes"
              />
              <RTVTreeItem
                isChild
                nodeId="22"
                labelIcon={() => (
                  <SvgIcon
                    className={classes.hammerIcon}
                    viewBox="0 0 34.587 35.882"
                  >
                    <HammerIcon />
                  </SvgIcon>
                )}
                onClick={() => this.props.push('/mis-ofertas')}
                labelText="Mis Ofertas"
              />
              <RTVTreeItem
                isChild
                nodeId="23"
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <BellIcon />
                  </SvgIcon>
                )}
                onClick={() => this.props.push('/mis-compras/mis-alertas')}
                labelText="Mis Alertas"
              />
            </RTVTreeItem>
            <RTVTreeItem
              nodeId="4"
              labelIcon={() => (
                <SvgIcon className={classes.labelIcon}>
                  <HeartIcon />
                </SvgIcon>
              )}
              labelText="Favoritos"
              onClick={() => this.props.push('/mis-favoritos')}
            />
            <RTVTreeItem
              nodeId="5"
              labelIcon={() => (
                <SvgIcon className={classes.labelIcon}>
                  <ThumbsUpIcon />
                </SvgIcon>
              )}
              labelText="Social"
              onClick={() => this.props.push('/social')}
            />
            <RTVTreeItem
              nodeId="7"
              labelIcon={() => (
                <SvgIcon className={classes.labelIcon}>
                  <SettingsIcon />
                </SvgIcon>
              )}
              labelText="Configuración"
            >
              <RTVTreeItem
                isChild
                nodeId="17"
                onClick={() =>
                  this.props.push(
                    `/vestidor/${this.props.loggedInUser.username}/sobre-mi`
                  )
                }
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <UserIcon />
                  </SvgIcon>
                )}
                labelText="Mi Perfil"
              />
              <RTVTreeItem
                isChild
                nodeId="18"
                onClick={() =>
                  this.props.push('/configuracion/datos-personales')
                }
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <FileTextIcon />
                  </SvgIcon>
                )}
                labelText="Datos Personales"
              />
              <RTVTreeItem
                isChild
                nodeId="19"
                onClick={() => this.props.push('/configuracion/mis-alertas')}
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <BellIcon />
                  </SvgIcon>
                )}
                labelText="Notificaciones"
              />
              <RTVTreeItem
                isChild
                nodeId="20"
                onClick={() =>
                  this.props.push('/configuracion/medios-de-envio')
                }
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <TruckIcon />
                  </SvgIcon>
                )}
                labelText="Medios de Envío"
              />
              <RTVTreeItem
                isChild
                nodeId="21"
                onClick={() =>
                  this.props.push('/configuracion/medios-de-cobro')
                }
                labelIcon={() => (
                  <SvgIcon viewBox="0 0 18 30" className={classes.dollarIcon}>
                    <DollarIcon />
                  </SvgIcon>
                )}
                labelText="Medios de Cobro"
              />
              <RTVTreeItem
                isChild
                nodeId="22"
                onClick={() => this.props.push('/configuracion/mis-facturas')}
                labelIcon={() => (
                  <SvgIcon className={classes.labelIcon}>
                    <BillIcon />
                  </SvgIcon>
                )}
                labelText="Mis Facturas"
              />
            </RTVTreeItem>
            <RTVTreeItem
              nodeId="8"
              onClick={() => {
                this.setState({ loggingOut: true });
              }}
              labelIcon={() => (
                <SvgIcon className={classes.labelIcon}>
                  <LogoutIcon />
                </SvgIcon>
              )}
              labelText="Salir"
            />
          </TreeView>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  privateStats: state.user.privateStats,
  userStatus: state.user.status,
  loggedInUser: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  logout: () => dispatch(logout())
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withWidth()(ProfileMenu))
);
