import React from 'react';
import { Link as NavLink } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  SvgIcon,
  Divider
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import numberWithCommas from '../../utils/numberWithCommas';

import backgroundImage from '../../images/popup_primeracompra1.jpg';
import { ReactComponent as XCircle } from '../../images/Icons/x-circle.svg';

const styles = theme => ({
  dialog: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  dialogBackground: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'round',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'unset'
    }
  },
  buyCampaign: {
    textAlign: 'center',
    padding: theme.spacing(4, 0, 0, 0),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 4, 4, 4)
    }
  },
  sellCampaign: {
    textAlign: 'center',
    padding: theme.spacing(4, 0, 0, 0),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 4, 4, 4)
    }
  },
  disclaimerText: {
    padding: theme.spacing(1, 0, 3, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0, 2, 0)
    }
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  button: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: '85%'
    }
  },
  header: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4)
    }
  },
  divider: {
    background: theme.palette.secondary.darker,
    margin: 'auto',
    height: '80%'
  },
  campaigns: {
    display: 'flex',
    gap: theme.spacing(3),
    padding: `0 ${theme.spacing(10)}px`,
    paddingRight: theme.spacing(11),
    height: theme.spacing(35),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      gap: 0,
      height: 'unset',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  singleCampaign: {
    padding: `0 ${theme.spacing(19)}px`,
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: `0 0 ${theme.spacing(2)}px 0`
    }
  },
  title: {
    color: theme.palette.secondary.darker,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },
  promoValue: {
    color: theme.palette.secondary.darker,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5rem!important'
    }
  },
  textContainer: {
    display: 'inline-block',
    position: 'relative',
    color: theme.palette.secondary.contrastText
  },
  textBackground: {
    position: 'absolute',
    top: '12%',
    left: 0,
    right: 0,
    height: '75%',
    background: theme.palette.secondary.darker
  },
  textWithBackground: {
    width: theme.spacing(23),
    position: 'relative',
    fontSize: '1.8rem',
    lineHeight: '2.8rem',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      lineHeight: '2.5rem'
    }
  }
});

const TextWithBackground = ({ classes, text }) => {
  return (
    <div className={classes.textContainer}>
      <div className={classes.textBackground}></div>
      <Typography variant="h4" className={classes.textWithBackground}>
        {text}
      </Typography>
    </div>
  );
};

const RTVRegisterPopup = ({
  open,
  onClose,
  welcomeBuyerCampaign,
  welcomeSellerCampaign,
  classes
}) => {
  const bothCampaigns = welcomeBuyerCampaign && welcomeSellerCampaign;
  return (
    <Dialog style={{ padding: 0 }} open={open} onClose={onClose}>
      <DialogContent style={{ padding: 0 }}>
        <div className={classes.dialog}>
          <SvgIcon className={classes.closeIcon} onClick={onClose}>
            <XCircle />
          </SvgIcon>
          <div className={classes.dialogBackground}>
            <div className={classes.header}>
              <Typography
                variant="h6"
                style={{ fontWeight: 500 }}
                className={classes.title}
              >
                Primera vez por acá?
              </Typography>
              <Typography
                variant="h5"
                style={{ fontWeight: 900 }}
                className={classes.title}
              >
                GRANDES BENEFICIOS
              </Typography>
            </div>
            <div
              className={
                bothCampaigns ? classes.campaigns : classes.singleCampaign
              }
            >
              {welcomeBuyerCampaign && (
                <div
                  className={classes.buyCampaign}
                  style={bothCampaigns && { paddingBottom: 0 }}
                >
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 1000 }}
                    className={classes.promoValue}
                  >
                    {`$${numberWithCommas(welcomeBuyerCampaign.amount)}`}
                  </Typography>
                  <TextWithBackground classes={classes} text="de regalo" />
                  <Typography
                    variant="body2"
                    className={classes.disclaimerText}
                  >
                    {`en tu primera compra + $${numberWithCommas(
                      parseInt(welcomeBuyerCampaign.minAmount) + 1
                    )}`}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                    component={NavLink}
                    className={classes.button}
                    to="/productos"
                  >
                    IR A COMPRAR
                  </Button>
                </div>
              )}
              {welcomeBuyerCampaign && welcomeSellerCampaign && (
                <Divider orientation="vertical" className={classes.divider} />
              )}
              {welcomeSellerCampaign && (
                <div className={classes.sellCampaign}>
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 1000 }}
                    className={classes.promoValue}
                  >
                    {welcomeSellerCampaign.amount}%
                  </Typography>
                  <TextWithBackground classes={classes} text="de comisión" />
                  <Typography
                    variant="body2"
                    className={classes.disclaimerText}
                  >
                    en tu primera venta
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                    component={NavLink}
                    className={classes.button}
                    to="/login"
                  >
                    PUBLICAR AHORA
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(RTVRegisterPopup);
