import React from 'react';
import { connect, useSelector } from 'react-redux';
import { TextField, Button, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { createReview } from '../redux/actions/user';
import AdornedButton from './AdornedButton';
import RTVAvatar from './RTVAvatar';
import RTVDialog from './dialogs/RTVDialog';
import {
  CREATE_REVIEW_REQUESTED,
  CREATE_REVIEW_SUCCESSFULL,
  CREATE_REVIEW_ERRORED
} from '../redux/actions/types';
import RTVRating from './RTVRating';
import removeEmoji from '../utils/removeEmoji';
import { setPendingReview } from '../redux/actions/auth';

const styles = () => ({
  curvedSearch: {
    width: '350px',
    '& .MuiInputBase-root': {
      borderRadius: '25px',
      backgroundColor: '#fdfbfc'
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '0.7rem'
    }
  },
  submitSection: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const RTVRate = ({
  order,
  createReview,
  setPendingReview,
  status,
  classes,
  showButton = true // showButton will be passed as a prop only when this component is rendered by AppRouting
}) => {
  const [dialog, setDialog] = React.useState(showButton ? false : true);
  const [noStarsError, setNoStarsError] = React.useState(false);
  const [stars, setStars] = React.useState(0);
  const [review, setReview] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const privateStats = useSelector(state => state.user.privateStats);

  React.useEffect(() => {
    if (status === CREATE_REVIEW_REQUESTED) {
      setLoading(true);
    }
    if (status === CREATE_REVIEW_SUCCESSFULL) {
      setLoading(false);
      setDialog(false);
      setReview('');
    }
    if (status === CREATE_REVIEW_ERRORED) {
      setLoading(false);
    }
  }, [status]);

  React.useEffect(() => {
    if (stars !== 0 && noStarsError) {
      setNoStarsError(false);
    }
    // noStarsError is guaranteed to be consistent.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stars]);

  const submitReview = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    createReview(order.id, review, stars);
    if (privateStats.pending_review) {
      setPendingReview(privateStats.pending_review.id);
    }
  };

  return (
    <>
      {order.sellers && (
        <RTVDialog
          open={dialog}
          onClose={() => {
            setDialog(false);
            if (privateStats.pending_review) {
              setPendingReview(privateStats.pending_review.id);
            }
          }}
          title="Calificá tu compra a"
          content={
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <RTVAvatar
                  src={
                    order.sellers[0].media
                      ? order.sellers[0].media.default_small ||
                        order.sellers[0].media.user_small
                      : null
                  }
                />
                <Typography style={{ marginLeft: '8px' }}>
                  {order.sellers[0].username}
                </Typography>
              </div>
              {!showButton && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography>Productos:</Typography>
                  <Box mt={2} style={{ textAlign: 'center' }}>
                    <Typography>
                      {order.products.length === 1
                        ? order.products[0].product.title
                        : [
                            order.products
                              .slice(0, order.products.length - 1)
                              .map(product => product.product.title)
                              .join(', '),
                            order.products[order.products.length - 1].product
                              .title
                          ].join(' y ')}
                    </Typography>
                  </Box>
                </div>
              )}
              <RTVRating
                name="review-rating"
                value={stars}
                onChange={(e, value) => value !== null && setStars(value)}
              />
              <TextField
                multiline
                rows={3}
                placeholder="Este mensaje se verá en el perfil de la vendedora. No incluyas datos de contacto ni links o tu respuesta será bloqueada."
                className={classes.curvedSearch}
                variant="outlined"
                value={review}
                onChange={e => setReview(removeEmoji(e.target.value))}
              />
            </>
          }
          actionSection={
            <div className={classes.submitSection}>
              <AdornedButton
                variant="contained"
                color="primary"
                loading={loading}
                disabled={loading || review.trim().length === 0}
                onClick={() =>
                  stars === 0 ? setNoStarsError(true) : submitReview()
                }
              >
                ENVIAR
              </AdornedButton>
              <Typography
                color="primary"
                style={{
                  fontWeight: 700,
                  marginTop: '8px',
                  opacity: noStarsError ? 1 : 0
                }}
              >
                No te olvides de darle una calificación a la vendedora!
              </Typography>
            </div>
          }
        />
      )}
      {showButton && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setDialog(true)}
        >
          CALIFICAR
        </Button>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  status: state.user.status
});

const mapDispatchToProps = dispatch => ({
  createReview: (order, review, stars) =>
    dispatch(createReview(order, review, stars)),
  setPendingReview: orderId => dispatch(setPendingReview(orderId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RTVRate));
