import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@material-ui/core';
import { ReactComponent as ConfettiIcon } from '../../images/Icons/congratulate-01.svg';

const styles = theme => ({
  primaryText: {
    fontSize: '1rem',
    divider: {
      borderBottomColor: fade(theme.palette.primary.main, 0.2)
    },
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      fontSize: '1rem'
    },
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column',
      alignItems: 'flex-start',
      '& > p': {
        marginLeft: '0 !important'
      }
    }
  },
  textLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  avatar: {
    backgroundColor: '#fff',
    width: '60px',
    height: '60px',
    boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.06)',
    '& > svg': {
      width: '50px',
      height: '50px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '56px',
      height: '56px'
    }
  },
  listAvatar: {
    marginRight: theme.spacing(1)
  }
});

const Welcome = ({ notification, key, classes, noBorder }) => (
  <ListItem
    key={key}
    alignItems="flex-start"
    divider={!noBorder}
    classes={{
      divider: classes.divider
    }}
  >
    <ListItemAvatar className={classes.listAvatar}>
      <Avatar className={classes.avatar} alt="avatar">
        <ConfettiIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Typography component="span" className={classes.primaryText}>
          Bienvenida a RTV
          <Typography style={{ marginLeft: '4px', fontWeight: 400 }}>
            · Donde podes vender la ropa que ya no usas, y comprar la que buscas
          </Typography>
        </Typography>
      }
      secondary={notification.timePassedString}
    />
  </ListItem>
);

export default withStyles(styles)(Welcome);
