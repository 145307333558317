import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link as NavLink } from 'react-router-dom';
import {
  Typography,
  Button,
  Avatar,
  SvgIcon,
  withWidth
} from '@material-ui/core';

import RoleIcon from '../RoleIcon';
import MailShareDialog from '../dialogs/RTVMailShareDialog';

import { ReactComponent as WhatsApp } from '../../images/Icons/whatsapp-01.svg';
import { ReactComponent as Facebook } from '../../images/Icons/facebook.svg';
import { ReactComponent as Twitter } from '../../images/Icons/twitter.svg';
import { ReactComponent as Mail } from '../../images/Icons/mail.svg';

const useStyles = makeStyles(theme => ({
  publishedSuccessfullMessage: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(90deg, ${theme.palette.gradients.showroom[0]} 0%, ${theme.palette.gradients.showroom[1]} 100%)`,
    '& > *': {
      textAlign: 'center'
    }
  },
  headerTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem'
    }
  },
  buttonGroup: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(2)
    }
  },
  socialLinkAvatar: {
    cursor: 'pointer',
    margin: theme.spacing(1),
    backgroundColor: '#707070'
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const PublishedShowroom = props => {
  const classes = useStyles();
  const { publishedProduct } = props;
  const shareQuote =
    'Miren+mi+nuevo+producto+publicado+en+RenovaTuVestidor! en';
  const [mailDialog, setMailDialog] = useState(false);
  const [mailString, setMailString] = useState('');
  const WHATSAPP_SHARE_URL =
    props.width === 'xs' || props.width === 'sm'
      ? 'whatsapp://'
      : 'https://wa.me/';

  const mailDialogConfirm = () => {
    const mailQuote =
      'Miren mi nuevo producto publicado en RenovaTuVestidor! en ';
    window.open(
      `mailto:${mailString}?&body=${mailQuote} ${window.encodeURIComponent(
        `${process.env.REACT_APP_WEB_URL}${publishedProduct.path}`
      )}`,
      '_blank'
    );
    setMailDialog(false);
  };

  return (
    <>
      <div className={classes.publishedSuccessfullMessage}>
        <MailShareDialog
          open={mailDialog}
          mailString={mailString}
          setMailString={setMailString}
          onClose={() => setMailDialog(false)}
          onConfirm={mailDialogConfirm}
        />
        <RoleIcon
          roleVariant="ROLE_SHOWROOM"
          style={{ width: '50px', height: '50px' }}
        />
        <Typography
          variant="h3"
          className={classes.headerTitle}
          style={{ color: '#fff', fontWeight: 800 }}
        >
          BENEFICIO SHOWROOM
        </Typography>
        <Typography variant="h4" style={{ color: '#fff' }}>
          Tu producto ya está publicado
        </Typography>
        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="secondary"
            component={NavLink}
            to={`/vestidor/${props.loggedInUser.username}`}
          >
            IR A MI SHOWROOM
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to="/vender"
          >
            SEGUIR PUBLICANDO
          </Button>
        </div>
        <Typography style={{ display: 'flex', fontWeight: 500 }}>
          Compartí y vendé mas rápido
        </Typography>
        <div className={classes.socialLinks}>
          <Avatar
            className={classes.socialLinkAvatar}
            component="a"
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?display=page&quote=${shareQuote}&u=${window.encodeURIComponent(
              `${process.env.REACT_APP_WEB_URL}${publishedProduct.path}`
            )}`}
          >
            <SvgIcon fontSize="small">
              <Facebook />
            </SvgIcon>
          </Avatar>
          <Avatar
            className={classes.socialLinkAvatar}
            component="a"
            target="_blank"
            href={`${WHATSAPP_SHARE_URL}send?text=${shareQuote}+${window.encodeURIComponent(
              `${process.env.REACT_APP_WEB_URL}${publishedProduct.path}`
            )}`}
            data-action="share/whatsapp/share"
          >
            <SvgIcon fontSize="small">
              <WhatsApp className={classes.whiteIcon} />
            </SvgIcon>
          </Avatar>
          <Avatar
            className={classes.socialLinkAvatar}
            component="a"
            target="_blank"
            href={`https://twitter.com/intent/tweet?text=${shareQuote}+${window.encodeURIComponent(
              `${process.env.REACT_APP_WEB_URL}${publishedProduct.path}`
            )}`}
          >
            <SvgIcon fontSize="small">
              <Twitter />
            </SvgIcon>
          </Avatar>
          <Avatar
            onClick={() => setMailDialog(true)}
            className={classes.socialLinkAvatar}
          >
            <SvgIcon fontSize="small">
              <Mail />
            </SvgIcon>
          </Avatar>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  loggedInUser: state.auth.user
});

export default connect(mapStateToProps)(withWidth()(PublishedShowroom));
