import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  SvgIcon,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import {
  SHOPPING_CART_REMOVE_REQUESTED,
  SHOPPING_CART_REMOVE_SUCCESSFULL,
  SHOPPING_CART_REMOVE_ERRORED
} from '../redux/actions/types';
import { removeFromCart, setShoppingCartPreview } from '../redux/actions/user';
import { ReactComponent as ShopBagIcon } from '../images/Icons/shopbag.svg';
import { ReactComponent as TrashIcon } from '../images/Icons/trash-2.svg';
import ProductImageFail from '../images/ProductImageFail.png';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      top: 'unset',
      bottom: 0,
      zIndex: 999,
      padding: 0,
      position: 'fixed',
      height: '45%'
    }
  },
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 500
  },
  container: {
    position: 'fixed',
    right: 0,
    height: '100%',
    width: theme.spacing(50),
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(7),
      height: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      top: 'unset',
      bottom: 0,
      height: 'auto',
      width: '100%'
    }
  },
  subContainer: {
    height: '90%',
    [theme.breakpoints.down('xs')]: {
      height: '80%',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 2,
    cursor: 'pointer',
    margin: theme.spacing(2),
    fontSize: '1.6rem',
    [theme.breakpoints.down('xs')]: {
      top: `-${theme.spacing(1)}px`,
      right: theme.spacing(1),
      margin: theme.spacing(1)
    }
  },
  header: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  headerText: {
    fontSize: '1rem',
    margin: 'auto',
    width: 'fit-content',
    fontWeight: 600
  },
  headerEmoji: {
    fontSize: '1rem'
  },
  subHeaderText: {
    margin: 'auto',
    width: 'fit-content',
    fontSize: '0.75rem',
    fontWeight: 400,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  productsSection: {
    marginTop: theme.spacing(2),
    height: '60%',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      maxHeight: theme.spacing(30)
    }
  },
  product: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1),
    alignItems: 'center'
  },
  productImage: {
    width: '65px',
    height: '80px',
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(2)
  },
  productHeader: {
    flex: 1,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  productTitle: {
    padding: theme.spacing(0.5),
    fontWeight: 600,
    textDecoration: 'none'
  },
  iconButton: {
    padding: 0,
    marginRight: theme.spacing(2)
  },
  emptyCart: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(5)
    }
  },
  loader: {
    padding: 0,
    marginRight: theme.spacing(2.5)
  },
  removeFromCart: {
    textDecoration: 'none',
    fontSize: '0.9rem',
    padding: 0,
    cursor: 'pointer'
  },
  buttonsSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  continueBuyingButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(2),
    fontWeight: 600,
    cursor: 'pointer',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  startPaymentButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(2),
    fontWeight: 600,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const CartPreview = ({
  shoppingCart,
  status,
  productRemoving,
  setShoppingCartPreview,
  removeFromCart
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const path = window.location.pathname;

  useEffect(() => {
    if (status === SHOPPING_CART_REMOVE_REQUESTED) {
      setLoading(true);
    }
    if (
      status === SHOPPING_CART_REMOVE_SUCCESSFULL ||
      status === SHOPPING_CART_REMOVE_ERRORED
    ) {
      setLoading(false);
    }
  }, [status, shoppingCart]);

  return (
    <div className={classes.root}>
      <div
        className={classes.background}
        onClick={() => setShoppingCartPreview(path, false)}
      />
      <div className={classes.container}>
        <Typography
          color="secondary"
          className={classes.closeButton}
          onClick={() => setShoppingCartPreview(path, false)}
        >
          x
        </Typography>
        <div className={classes.subContainer}>
          <div className={classes.header}>
            <Typography color="primary" className={classes.headerText}>
              Se agregó el producto{' '}
              <span
                className={classes.headerEmoji}
                role="img"
                aria-label="emoji"
              >
                🤩
              </span>
            </Typography>
            <Typography
              color="primary"
              className={classes.subHeaderText}
              onClick={() => {
                setShoppingCartPreview(path, false);
                window.location.replace('/checkout/carrito');
              }}
            >
              Ver carrito de compras
            </Typography>
          </div>
          <div
            className={classes.productsSection}
            style={{
              alignContent: shoppingCart.length === 0 ? 'center' : 'start'
            }}
          >
            {shoppingCart.map(product => (
              <div className={classes.product} key={product.id}>
                <Link to={`/producto/id/${product.id}`} target="_blank">
                  <img
                    className={classes.productImage}
                    src={
                      product.main_image &&
                      Object.keys(product.main_image).length !== 0
                        ? product.main_image.default_small ||
                          product.main_image.product_small
                        : ProductImageFail
                    }
                    alt={product.title}
                  />
                </Link>
                <div className={classes.productHeader}>
                  <Typography
                    className={classes.productTitle}
                    color="secondary"
                    component={Link}
                    to={`/producto/id/${product.id}`}
                    target="_blank"
                  >
                    {product.title}
                  </Typography>
                </div>
                {loading && productRemoving === product.id ? (
                  <CircularProgress
                    className={classes.loader}
                    color="secondary"
                    size={13}
                  />
                ) : (
                  <IconButton
                    onClick={() => {
                      if (loading) return;
                      removeFromCart(product.id);
                    }}
                    className={classes.iconButton}
                  >
                    <SvgIcon
                      color="secondary"
                      className={classes.removeFromCart}
                    >
                      <TrashIcon />
                    </SvgIcon>
                  </IconButton>
                )}
              </div>
            ))}
            {shoppingCart.length === 0 && (
              <Typography color="secondary" className={classes.emptyCart}>
                Tu carrito está vacio
              </Typography>
            )}
          </div>
          <div className={classes.buttonsSection}>
            <Button
              className={classes.continueBuyingButton}
              onClick={() => setShoppingCartPreview(path, false)}
            >
              SEGUIR COMPRANDO
            </Button>
            <Button
              className={classes.startPaymentButton}
              onClick={() => {
                setShoppingCartPreview(path, false);
                window.location.replace('/checkout/carrito');
              }}
            >
              <SvgIcon fontSize="small" style={{ marginRight: '8px' }}>
                <ShopBagIcon style={{ stroke: '#fff' }} />
              </SvgIcon>
              IR AL CARRITO
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  status: state.user.status,
  shoppingCart: state.user.shoppingCart,
  productRemoving: state.user.productRemoving
});

const mapDispatchToProps = dispatch => ({
  removeFromCart: product => dispatch(removeFromCart(product)),
  setShoppingCartPreview: (path, open) =>
    dispatch(setShoppingCartPreview(path, open))
});

export default connect(mapStateToProps, mapDispatchToProps)(CartPreview);
