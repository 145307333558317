/* eslint-disable max-len */
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
  MenuList,
  List,
  ListItem,
  Button,
  SvgIcon,
  Badge,
  Drawer
} from '@material-ui/core';
import { fade, withStyles } from '@material-ui/core/styles';

import {
  Search,
  FavoriteBorder,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Menu,
  HomeOutlined,
  ArrowBack
} from '@material-ui/icons';

import ProfileMenu from '../menus/ProfileMenu';
import RTVMenu from '../RTVMenu';
import RTVAvatar from '../RTVAvatar';

import { ReactComponent as InfoIcon } from '../../images/Icons/info.svg';
import { ReactComponent as ShopBagIcon } from '../../images/Icons/shopbag.svg';
import { ReactComponent as BellIcon } from '../../images/Icons/bell.svg';
import { ReactComponent as HangerIcon } from '../../images/Icons/hanger.svg';
import { ReactComponent as PremiumIcon } from '../../images/Icons/Premium.svg';
import { ReactComponent as ShowroomIcon } from '../../images/Icons/Showroom.svg';
import { ReactComponent as CelebritiesIcon } from '../../images/Icons/Celebrities.svg';
import { ReactComponent as SolidaryIcon } from '../../images/Icons/Solidary.svg';
import { ReactComponent as ZapIcon } from '../../images/Icons/zap.svg';
import Logo from '../../images/logo.svg';
import RenovaLogo from '../../images/ten-years-logo-transparent.png';

const styles = theme => ({
  appContent: {
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  toolbar: {
    background: '#000',
    textAlign: 'center',
    color: '#fff'
  },
  title: {
    flexGrow: 1,
    '& > h5': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  message: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: theme.spacing(0, 0, -2, -2),
    cursor: 'pointer'
  },
  liMenu: {
    height: '1rem',
    paddingTop: 0,
    paddingBottom: 0
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    '& > li': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
      '& > li': {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  },
  submenu: {
    width: 'unset',
    justifyContent: 'center',
    '& > li': {
      paddingLeft: 0,
      paddingRight: 0,
      '& > button': {
        color: '#fff !important'
      }
    }
  },
  notFound: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: theme.spacing(4),
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  search: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    minWidth: '200px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    },
    '& .MuiInputBase-root': {
      backgroundColor: fade(theme.palette.common.white, 0.9),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 1)
      },
      borderRadius: '25px'
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  },
  infoButton: {
    position: 'absolute',
    padding: theme.spacing(0.25),
    right: 0,
    top: '65px',
    fontSize: '.7rem',
    background: '#000',
    color: '#fff',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    fontSize: '.8rem'
  },
  mobileToolbar: {
    background: '#1f1f1f',
    color: '#fff',
    '& > div': {
      maxHeight: '55px',
      paddingRight: 0
    }
  },
  mobileMenu: {
    overflow: 'auto',
    background: '#1f1f1f',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
    color: '#fff',
    height: '100%',
    width: '250px',
    padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  drawer: {
    width: '250px'
  },
  mobileRightMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1
  },
  avatarMobile: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '70px',
    height: '70px',
    border: '3px solid #1f1f1f',
    overflow: 'hidden',
    '& > span': {
      '& > div': {
        width: '70px',
        height: '70px'
      }
    }
  },
  searchBar: {
    margin: theme.spacing(1),
    marginTop: `calc(${theme.spacing(1)}px + 55px)`
  },
  inputMobile: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  inputs: {
    fontSize: '16px !important',
    '& > fieldset': {
      fontSize: '0.8rem',
      border: 'none',
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
      '&:hover': {
        border: 'none'
      },
      '&:active': {
        border: 'none'
      }
    },
    '&:hover': {
      border: 'none'
    }
  },
  categories: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    width: '200px !important',
    marginLeft: '37px !important',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    marginTop: '0 !important'
  },
  category: {
    width: '100%',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    '& > span': {
      flex: 1
    }
  },
  popper: {
    marginTop: `-${theme.spacing(1)}px`
  },
  RTVLogo: {
    width: '60px'
  },
  navBarButton: {
    letterSpacing: '0.03rem'
  }
});

class Mobile extends Component {
  constructor() {
    super();

    this.avatarRef = React.createRef();
    this.searchRef = React.createRef();

    this.state = {
      categorySelected: null,
      subCategorySelected: null,
      brandsMenu: false,
      dresserMenu: false,
      profileMenu: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchMode !== this.props.searchMode) {
      if (this.searchRef) {
        this.searchRef.focus();
      }
    }
  }

  render() {
    const { classes, avatar } = this.props;

    return (
      <>
        <RTVMenu
          open={this.state.profileMenu}
          anchorEl={this.avatarRef.current}
          placement="bottom"
          onClose={() => this.setState({ profileMenu: false })}
          classes={{
            popper: classes.popper
          }}
          transition
        >
          <ProfileMenu user={this.props.user} />
        </RTVMenu>
        <AppBar position="fixed" className={classes.mobileToolbar}>
          {this.props.noBar && (
            <IconButton className={classes.avatarMobile}>
              <RTVAvatar src={Logo} alt="renova tu vestidor logo" />
            </IconButton>
          )}
          {!this.props.noBar && (
            <Toolbar>
              <IconButton
                style={{ margin: 0 }}
                onClick={() =>
                  this.setState(state => ({
                    ...state,
                    openDrawer: !state.openDrawer
                  }))
                }
                edge="start"
                // eslint-disable-next-line react/jsx-no-duplicate-props
                style={{ marginRight: '8px' }}
                color="inherit"
                aria-label="menu"
              >
                <Menu />
              </IconButton>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/vender"
                className={classes.navBarButton}
              >
                VENDER
              </Button>
              <div className={classes.mobileRightMenu}>
                {!this.props.isLoggedIn && (
                  <>
                    <Button
                      style={{ background: '#1f1f1f', color: '#fff' }}
                      component={Link}
                      to="/login"
                      className={classes.navBarButton}
                    >
                      INGRESAR
                    </Button>
                    <IconButton className={classes.avatarMobile}>
                      <Avatar style={{ backgroundColor: '#1f1f1f' }}>
                        <HangerIcon
                          style={{ fill: '#f16067', width: '70px' }}
                        />
                      </Avatar>
                    </IconButton>
                  </>
                )}
                {this.props.isLoggedIn && !this.props.noProfil && (
                  <>
                    <MenuList className={`${classes.menu} ${classes.submenu}`}>
                      <MenuItem>
                        <IconButton
                          component={Link}
                          className={clsx(
                            classes.menuActionIcon,
                            classes.menuOptional
                          )}
                          style={{ color: '#fff' }}
                          to="/mis-favoritos"
                        >
                          <FavoriteBorder />
                        </IconButton>
                      </MenuItem>
                      <MenuItem>
                        <IconButton component={NavLink} to="/checkout/carrito">
                          <Badge
                            color="primary"
                            badgeContent={
                              this.props.privateStats.shopping_cart_count
                            }
                          >
                            <SvgIcon>
                              <ShopBagIcon style={{ stroke: '#fff' }} />
                            </SvgIcon>
                          </Badge>
                        </IconButton>
                      </MenuItem>
                      <MenuItem>
                        <IconButton
                          component={Link}
                          to="/notificaciones/todas"
                          style={{ color: '#fff' }}
                        >
                          <Badge
                            color="primary"
                            badgeContent={
                              this.props.privateStats.notifications_count
                            }
                          >
                            <BellIcon style={{ transform: 'rotate(-10deg)' }} />
                          </Badge>
                        </IconButton>
                      </MenuItem>
                    </MenuList>
                    <IconButton
                      ref={this.avatarRef}
                      onClick={() => this.setState({ profileMenu: true })}
                      className={classes.avatarMobile}
                    >
                      <RTVAvatar
                        src={avatar}
                        alt={this.props.user ? this.props.user.username : ''}
                      />
                    </IconButton>
                  </>
                )}
              </div>
            </Toolbar>
          )}
        </AppBar>

        <Drawer
          anchor="left"
          open={this.state.openDrawer}
          onClose={() =>
            this.setState({
              openDrawer: false
            })
          }
          classes={{
            root: classes.drawer
          }}
        >
          <List className={classes.mobileMenu}>
            <ListItem
              style={{
                margin: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img
                className={classes.RTVLogo}
                src={RenovaLogo}
                alt="renova tu vestidor"
              />
              <IconButton
                onClick={() =>
                  this.setState(state => ({
                    ...state,
                    openDrawer: !state.openDrawer
                  }))
                }
                color="inherit"
              >
                <Menu />
              </IconButton>
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/vender"
                style={{ width: '100%', letterSpacing: '0.03rem' }}
              >
                VENDER
              </Button>
            </ListItem>
            <ListItem>
              <Typography
                component={Link}
                to="/"
                style={{
                  fontWeight: 800,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#fff',
                  textDecoration: 'none'
                }}
              >
                <HomeOutlined style={{ marginRight: '4px' }} />
                Home
              </Typography>
            </ListItem>
            <ListItem style={{ marginBottom: 0 }}>
              <Typography
                style={{
                  fontWeight: 800,
                  display: 'flex',
                  alignItems: 'center',
                  color: '#fff',
                  textDecoration: 'none'
                }}
                component={Link}
                to="/productos"
              >
                <SvgIcon style={{ marginRight: '4px' }}>
                  <ShopBagIcon style={{ stroke: '#fff' }} />
                </SvgIcon>
                Comprar
              </Typography>
            </ListItem>
            {this.state.categorySelected === null &&
              !this.state.brandsMenu &&
              !this.state.dresserMenu && (
                <>
                  <List className={classes.categories}>
                    <ListItem>
                      <Typography
                        onClick={() =>
                          this.setState({
                            categorySelected: 'mujer'
                          })
                        }
                        component="span"
                        className={classes.category}
                      >
                        <span>Mujer</span>
                        <ChevronRightIcon style={{ marginLeft: '4px' }} />
                      </Typography>
                    </ListItem>
                    {this.props.categories.hasOwnProperty('hombre') && (
                      <ListItem>
                        <Typography
                          onClick={() =>
                            this.setState({
                              categorySelected: 'hombre'
                            })
                          }
                          component="span"
                          className={classes.category}
                        >
                          <span>Hombre</span>
                          <ChevronRightIcon style={{ marginLeft: '4px' }} />
                        </Typography>
                      </ListItem>
                    )}
                    <ListItem>
                      <Typography
                        onClick={() =>
                          this.setState({
                            categorySelected: 'kids'
                          })
                        }
                        component="span"
                        className={classes.category}
                      >
                        <span>Kids</span>
                        <ChevronRightIcon style={{ marginLeft: '4px' }} />
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        component="span"
                        className={classes.category}
                        onClick={() =>
                          this.setState({
                            brandsMenu: true
                          })
                        }
                      >
                        <span>Marcas</span>
                        <ChevronRightIcon style={{ marginLeft: '4px' }} />
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography
                        component="span"
                        className={classes.category}
                        onClick={() =>
                          this.setState({
                            dresserMenu: true
                          })
                        }
                      >
                        <span>Vestidores</span>
                        <ChevronRightIcon style={{ marginLeft: '4px' }} />
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography component="span" className={classes.category}>
                        <span>Showrooms</span>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography component="span" className={classes.category}>
                        <span>Promos</span>
                      </Typography>
                    </ListItem>
                  </List>
                  <ListItem>
                    <Typography
                      style={{
                        fontWeight: 800,
                        display: 'flex',
                        alignItems: 'center',
                        color: '#fff',
                        textDecoration: 'none'
                      }}
                      component={Link}
                      to={
                        this.props.isLoggedIn
                          ? '/ayuda/como-funciona'
                          : '/como-funciona'
                      }
                    >
                      <SvgIcon style={{ marginRight: '4px' }}>
                        <InfoIcon />
                      </SvgIcon>
                      {this.props.isLoggedIn ? 'Ayuda' : 'Cómo funciona'}
                    </Typography>
                  </ListItem>
                </>
              )}
            {this.state.categorySelected !== null &&
              this.state.subCategorySelected === null && (
                <List
                  style={{ marginLeft: '37px' }}
                  subheader={
                    <Typography
                      className={classes.category}
                      onClick={() => this.setState({ categorySelected: null })}
                      style={{
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <ChevronLeftIcon style={{ marginRight: '4px' }} />
                      {this.state.categorySelected}
                    </Typography>
                  }
                >
                  {Object.keys(
                    this.props.categories[this.state.categorySelected]
                      .sub_categories
                  )
                    .filter(
                      c =>
                        !this.props.categories[this.state.categorySelected]
                          .sub_categories[c].disabled_shop
                    )
                    .map(category => (
                      <ListItem
                        key={
                          this.props.categories[this.state.categorySelected]
                            .sub_categories[category].id
                        }
                      >
                        <Typography
                          className={classes.category}
                          onClick={() =>
                            this.setState({ subCategorySelected: category })
                          }
                        >
                          {
                            this.props.categories[this.state.categorySelected]
                              .sub_categories[category].name
                          }
                        </Typography>
                      </ListItem>
                    ))}
                  <ListItem>
                    <Typography
                      className={classes.category}
                      component={Link}
                      style={{ color: '#fff', textDecoration: 'none' }}
                      to={`/productos/${this.state.categorySelected}`}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      className={classes.category}
                    >
                      Ver todo
                    </Typography>
                  </ListItem>
                </List>
              )}
            {this.state.categorySelected !== null &&
              this.state.subCategorySelected !== null && (
                <List
                  style={{ marginLeft: '37px' }}
                  subheader={
                    <Typography
                      className={classes.category}
                      onClick={() =>
                        this.setState({ subCategorySelected: null })
                      }
                      style={{
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <ChevronLeftIcon style={{ marginRight: '4px' }} />
                      {
                        this.props.categories[this.state.categorySelected]
                          .sub_categories[this.state.subCategorySelected].name
                      }
                    </Typography>
                  }
                >
                  {Object.keys(
                    this.props.categories[this.state.categorySelected]
                      .sub_categories[this.state.subCategorySelected]
                      .sub_sub_categories
                  )
                    .filter(
                      c =>
                        !this.props.categories[this.state.categorySelected]
                          .sub_categories[this.state.subCategorySelected]
                          .sub_sub_categories[c].disabled_shop
                    )
                    .map(category => (
                      <ListItem
                        key={
                          this.props.categories[this.state.categorySelected]
                            .sub_categories[this.state.subCategorySelected]
                            .sub_sub_categories[category].id
                        }
                      >
                        <Typography
                          className={classes.category}
                          component={Link}
                          style={{ color: '#fff', textDecoration: 'none' }}
                          to={`/productos/${this.state.categorySelected}/${this.state.subCategorySelected}/${category}`}
                        >
                          {
                            this.props.categories[this.state.categorySelected]
                              .sub_categories[this.state.subCategorySelected]
                              .sub_sub_categories[category].name
                          }
                        </Typography>
                      </ListItem>
                    ))}
                  <ListItem>
                    <Typography
                      className={classes.category}
                      component={Link}
                      style={{ color: '#fff', textDecoration: 'none' }}
                      to={`/productos/${this.state.categorySelected}/${this.state.subCategorySelected}`}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      className={classes.category}
                    >
                      Ver todo
                    </Typography>
                  </ListItem>
                </List>
              )}
            {this.state.brandsMenu && this.state.categorySelected === null && (
              <List
                style={{ marginLeft: '37px' }}
                subheader={
                  <Typography
                    className={classes.category}
                    style={{
                      textTransform: 'uppercase',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    onClick={() => this.setState({ brandsMenu: false })}
                  >
                    <ChevronLeftIcon />
                    MARCAS
                  </Typography>
                }
              >
                {this.props.brands
                  .filter(b => b.featured)
                  .map(brand => (
                    <ListItem className={classes.category} key={brand.id}>
                      <Typography
                        className={classes.category}
                        component={Link}
                        style={{ color: '#fff', textDecoration: 'none' }}
                        to={`/productos/marca/${brand.id}`}
                      >
                        {brand.name}
                      </Typography>
                    </ListItem>
                  ))}
              </List>
            )}
            {this.state.dresserMenu && (
              <List
                style={{ marginLeft: '37px' }}
                subheader={
                  <Typography
                    className={classes.category}
                    onClick={() => this.setState({ dresserMenu: false })}
                    style={{
                      textTransform: 'uppercase',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <ChevronLeftIcon />
                    VESTIDORES
                  </Typography>
                }
              >
                <ListItem
                  className={classes.category}
                  component={Link}
                  to="/vestidores/premium"
                >
                  <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>Premium</span>
                    <SvgIcon style={{ marginLeft: '8px' }} viewBox="0 0 72 37">
                      <PremiumIcon />
                    </SvgIcon>
                  </Typography>
                </ListItem>
                <ListItem
                  className={classes.category}
                  component={Link}
                  to="/vestidores/celebrities"
                >
                  <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>Celebrities</span>
                    <SvgIcon style={{ marginLeft: '8px' }} viewBox="0 0 50 43">
                      <CelebritiesIcon />
                    </SvgIcon>
                  </Typography>
                </ListItem>
                <ListItem
                  className={classes.category}
                  component={Link}
                  to="/vestidores/showrooms"
                >
                  <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>Showrooms</span>
                    <SvgIcon style={{ marginLeft: '8px' }} viewBox="0 0 48 31">
                      <ShowroomIcon />
                    </SvgIcon>
                  </Typography>
                </ListItem>
                <ListItem
                  className={classes.category}
                  component={Link}
                  to="/vestidores/solidary"
                >
                  <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>Solidarios</span>
                    <SvgIcon style={{ marginLeft: '8px' }} viewBox="0 0 48 31">
                      <SolidaryIcon />
                    </SvgIcon>
                  </Typography>
                </ListItem>
                <ListItem
                  className={classes.category}
                  component={Link}
                  to="/vestidores?filter=promo"
                >
                  <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>En Promo Hoy</span>
                    <SvgIcon style={{ marginLeft: '8px' }} viewBox="0 0 44 59">
                      <ZapIcon />
                    </SvgIcon>
                  </Typography>
                </ListItem>
                <ListItem
                  className={classes.category}
                  component={Link}
                  to="/vestidores"
                >
                  <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ flex: 1 }}>Ver todos</span>
                    <ChevronRightIcon
                      style={{ marginLeft: '8px' }}
                      color="primary"
                    />
                  </Typography>
                </ListItem>
              </List>
            )}
          </List>
        </Drawer>
        {this.props.mobileSearchable && (
          <div className={classes.searchBar}>
            <TextField
              fullWidth
              type="text"
              inputRef={input => {
                this.searchRef = input;
              }}
              variant="outlined"
              value={this.props.search}
              onClick={this.props.focusSearch}
              placeholder="Buscar"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.props.focusSearch(false);
                  this.props.goSearch();
                }
              }}
              InputProps={{
                classes: {
                  root: classes.inputs,
                  input: classes.inputMobile
                },
                startAdornment: this.props.searchFocused ? (
                  <InputAdornment
                    style={{
                      height: '100%',
                      pointerEvents: 'all',
                      cursor: 'pointer'
                    }}
                    position="start"
                    onClick={e => {
                      e.stopPropagation();
                      this.props.focusSearch(false);
                    }}
                  >
                    <ArrowBack />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="start">
                    <Search style={{ opacity: 0.8 }} />
                  </InputAdornment>
                )
              }}
              onChange={this.props.handleSearchChange}
            />
          </div>
        )}
      </>
    );
  }
}

export default withStyles(styles)(Mobile);
